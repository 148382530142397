.nonEditableFieldsText {
    margin-bottom: 4px;
    font-weight: bold;
    color: grey;
    opacity: 0.5;
}

.gridStyle {
    width: 350px;
}

.translationFieldsStyle {
    margin-bottom: 20px;
}

.animationLabelsTitle {
    margin-bottom: 4px;
    margin-top: 10px;
    font-weight: bold;
}
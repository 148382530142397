.showDialogCloseIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
}

.questionDetailsSelector {
    width: 60%;
    margin-bottom: 15px
}

.localizationFields {
    width: 80%;
}
.backsideContentTabs {
    width: 50%;
}

.animStepsInput {
    width: 450px;
    flex: 1
}
